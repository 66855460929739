import '../scss/style.scss'
// PHPで画像を指定するのみではwebpackがdistに出力してくれないみたいなのでここで指定のみしておく
// ph1/ph6はscss上で読み込むのでここでは必要ないです
import loading from '../img/img-loading.gif'
import ph2 from '../img/ph2.png'
import ph3 from '../img/ph3.png'
import ph4 from '../img/ph4.png'
import ph5 from '../img/ph5.png'
import ttl_ironpress from '../img/ttl_ironpress.png'
import ttl_contact from '../img/ttl_contact.png'
import ttl_works from '../img/ttl_works.png'
import ttl_service from '../img/ttl_service.png'
import ttl_jopress from '../img/ttl_jopress.png'
import ttl_gotopress from '../img/ttl_gotopress.png'
import OGP from '../img/OGP.png'

import $ from 'jquery'
import _ from 'lodash'
import modernizr from 'modernizr'
import scrollMonitor from 'scrollmonitor'
import Parlx from 'parlx.js'
import imagesLoaded from 'imagesloaded'



$(function() {
  var h = $(window).height();

  $('#wrap').css('display','none');
  $('#loader-bg ,#loader').height(h).css('display','block');

  //10秒たったら強制的にロード画面を非表示
  setTimeout(stopload, 15000);
});

imagesLoaded(document.getElementById('kv'), {background: true}, () => {
  stopload();
  app.bind();
});

function stopload() {
  if ( !app.isLoad ) { return; }
  app.isLoad = false;
  $('#wrap').css('display','block');
  $('#loader-bg').delay(900).fadeOut(800);
  $('#loader').delay(600).fadeOut(300);
}



const app = {};

app.bind = function () {
  let elementWatcher = [];
  _.forEach(document.querySelectorAll('.js-watcher'), function(item, index) {
    elementWatcher[index] = scrollMonitor.create( item );
  });

  let isActiveElement = [];

  _.forEach(elementWatcher, function(item, index) {
    item.enterViewport(function(e) {
      console.log(e);
      let _inview = _.filter(this.container.watchers, {isInViewport: true});
      let inview = _.filter(_inview, function(item) {
        return !item.watchItem.classList.contains('-is-active');
      });
      if (inview.length > 0 && this.watchItem == _.last(inview).watchItem){
        _.forEach(inview, function(item, index) {
          $(item.watchItem)
            .css('transition-delay', String(index * 0.1) + 's, ' + String(index * 0.1) + 's, ' + String(index * 0.1) + 's')
            .addClass('-is-active');
        });
      }
    });
  });
}

app.isConnect = false;
app.isLoad = true;

$(document).on('submit', '#mailform', function(e){
  e.preventDefault();
  if ( app.isConnect ) {
    return;
  }
  app.isConnect = true;

  // ローディング開始
  var data = $(this).serializeArray();
  var array = {};
  $.each(data, function(i) {
    array[data[i].name] = data[i].value;
  });

  $('.js-input').removeClass('inp_error');
  $('.js-errormsg').empty();

  $.ajax({
    url: '/component/sendmail.php',
    type: 'POST',
    headers: {
      'X-CSRF-Token': token
    },
    data: array,
    timeout: 10000,
    async: true
  })
  .done((res) => {
    var response = JSON.parse(res);

    if ( input_check(response) ){
      window.alert('送信完了しました。ご返信までしばらくお待ちください。');
      $(this)[0].reset();
    } else {
      $.each(response, (key, item) => {
        $('#' + key + '_error').html('<i class="fa fa-exclamation-circle"></i>' + item).addClass('is-active');
        $('#form_' + key).addClass('inp_error');
      });
    }
  })
  .fail((res) =>{
    // エラー
    console.log(res);
  })
  .always(() => {
    //ローディング終了
    app.isConnect = false;
  });
});

$(document).on('change', '#nav_open', function() {
  $('#nav_btn').toggleClass('-is-active');
  $('.nav_btn_caption').toggleClass('-is-active');
});

const elems = document.querySelectorAll('.js-parlx');//.parallaxの代わりに#[ID]または.[クラス名]
const parlx = new Parlx(elems, {
  direction: 'vertical', // parallax要素の動く方向 'horizontal', 'diagonal'
  type: 'foreground', // parallaxの種類 'foreground '(div move), 'background' (inner image move)
  speed: 0.4, // parallaxのスピードを-1から1の数値で
  height: 800, // parallaxを適用するコンテナの高さを数値で
  mobile: true, // parallaxをSPにも適用するか true or false
});

// 入力内容チェックのための関数
function input_check(errors) {
  var result = true;

  // errorsに1が入っていたら送信成功とみなす
  if ( !isNaN(errors) ){
    return true;
  }

  return false;
}